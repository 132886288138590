import React from 'react';
import { Navbar, Nav, Container, Button, Card } from 'react-bootstrap';
import { FaLinkedin } from 'react-icons/fa';  // Import LinkedIn icon from React Icons
import './App.css';  // Import your CSS file

const App = () => {
  return (
    <div className="App">
      {/* Navbar */}
      <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#home">
            <img src="BlockEstateLogo.png" alt="BlockEstateRealty Logo" className="logo" />
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
            {/* LinkedIn Icon in the Navbar */}
          </Nav>
        </Container>
      </Navbar>

      {/* Hero Section */}
      <section className="hero-section">
        <div className="overlay"></div>
        <div className="container text-center">
          <h1 className="text-shadow">Welcome to BlockEstateRealty</h1>
          <p className="text-shadow">
            Revolutionizing Real Estate Investment through Tokenization
          </p>
          <Button variant="primary" href="#services">Learn More</Button>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="services-page">
        <Container>
          <h2 className="text-center">Our Tokenized Real Estate Services</h2>
          <p className="text-center">
            BlockEstateRealty is revolutionizing real estate investment with blockchain technology. Through tokenization,
            we allow you to invest in fractional ownership of high-value real estate properties using crypto NFTs.
            Our platform ensures transparency, security, and accessibility, opening the door for everyone to access the
            lucrative real estate market, regardless of their investment size.
          </p>
          
          <div className="services-list">
            <div className="service-item">
              <h3>Fractional Ownership</h3>
              <p>
                Invest in shares of real estate properties, allowing you to diversify your portfolio with a much smaller capital
                commitment than traditional real estate investing. Tokenization breaks down the cost of properties into affordable
                fractional shares, making it accessible to more people.
              </p>
            </div>
      
            <div className="service-item">
              <h3>Blockchain Transparency</h3>
              <p>
                All transactions are recorded on the blockchain, providing a transparent and immutable ledger. This means
                that you can easily verify ownership, transactions, and more, ensuring that your investments are secure.
              </p>
            </div>
      
            <div className="service-item">
              <h3>Security and Trust</h3>
              <p>
                Our platform uses advanced encryption and smart contracts to ensure that your investments are safe and your
                returns are automated. The decentralized nature of blockchain technology provides an additional layer of
                security for all parties involved.
              </p>
            </div>
      
            <div className="service-item">
              <h3>Global Access</h3>
              <p>
                With our platform, investors from around the world can participate in real estate investments. Gone are the days
                of geographic limitations—now you can invest in properties from different regions, all through our easy-to-use platform.
              </p>
            </div>
          </div>
      
          <div className="text-center">
            <Button variant="primary" href="#contact">Get in Touch</Button>
          </div>
        </Container>
      </section>


      {/* Contact Section */}
      <section id="contact" className="contact-section">
        <Container>
          <h2 className="text-center">Contact Us</h2>
          <Card className="contact-card">
            {/* Profile Image */}
            <Card.Img variant="top" src="markusBreitbach.jpg" alt="Markus Breitbach" className="profile-image" />
            <Card.Body>
              <Card.Text>
                Email: <a href="mailto:info@blockestaterealty.com">Welcome@blockestaterealty.com</a>
              </Card.Text>
              <Card.Text>
                Phone: <a href="tel:+123456789">+1 (234) 567-89</a>
              </Card.Text>
              <Card.Text>
                LinkedIn: 
                <a href="https://www.linkedin.com/in/markusbreitbach/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={20} color="#0077b5" /> Markus Breitbach
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </section>

      {/* Footer */}
      <footer>
        <p className="text-center">&copy; 2025 BlockEstateRealty</p>
      </footer>
    </div>
  );
};

export default App;